/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
// @import "~@ionic/angular/css/padding.css";
// @import "~@ionic/angular/css/float-elements.css";
// @import "~@ionic/angular/css/text-alignment.css";
// @import "~@ionic/angular/css/text-transformation.css";
// @import "~@ionic/angular/css/flex-utils.css";

@import 'tailwindcss/base';

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';

@font-face {
    font-family: Rubik-Light;
    src: url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;500;700&display=swap');
  }
// @font-face {
//     font-family: RubikLight;
//     src: url('https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap');
//   }
//   @font-face {
//     font-family: RubikMedium;
//     src: url('https://fonts.googleapis.com/css2?family=Nunito:wght@500&display=swap');
//   }
//   @font-face {
//     font-family: RubikBold;
//     src: url('https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap');
//   }
//   @font-face {
//     font-family: 'Nunito', sans-serif;
//     src: url('https://fonts.googleapis.com/css2?family=Nunito:wght@500&display=swap');
//   }
// //   .Rubik-Light{
// //     font-family: 'Nunito', sans-serif;
// //   }
// //   .Rubik-Medium{
// //     font-family: RubikMedium;
// //   }
//   .Rubik-Bold{
//     font-family: 'Nunito', sans-serif;
//   }
